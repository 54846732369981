import {Col, Container, Row} from "react-bootstrap";
import links from "../links.js";
import strings from "../strings.js";
import "./HomeFooter.css";

function HomeFooter() {
	const iconLinks = [
		{
			alt: "WhatsApp",
			path: "/images/whatsapp.svg",
			url: "https://api.whatsapp.com/send/?phone=390957463250"
		},
		{
			alt: "Facebook",
			path: "/images/facebook.svg",
			url: "https://www.facebook.com/HT.Apps.WebApplications.Agritech/"
		},
		{
			alt: "LinkedIn",
			path: "/images/linkedin.svg",
			url: "https://it.linkedin.com/company/ht-apps"
		},
		{
			alt: "YouTube",
			path: "/images/youtube.svg",
			url: strings.youtubeURL
		},
		{
			alt: "Flipboard",
			path: "/images/flipboard.svg",
			url: "https://flipboard.com/@JOGroup"
		},
		{
			alt: "Google News",
			path: "/images/google_news.svg",
			url: "https://news.google.com/s/CBIwwcfU7LMB"
		},
		{
			alt: "PDF",
			path: "/images/pdf.svg",
			url: "https://www.jogroup.eu/wp-content/uploads/company-profile/HT-Apps-Company-Profile-ita.pdf"
		},
	];

	const textLinks = [
		{
			text: strings.privacyPolicy,
			url: links.privacyPolicy
		},
		{
			text: strings.cookiePolicy,
			url: links.cookiePolicy
		}
	];

	return (
		<Container id="home-footer" className="d-grid gap-4 gap-sm-3 mb-5">
			<Row>
				<Col><img src="/images/separator_large.svg" alt="" /></Col>
			</Row>
			<Row>
				<Col className="user-select-none transparent-cursor" dangerouslySetInnerHTML={{__html: `<span class="text-nowrap">${strings.formatString(strings.companyYears, (new Date()).getFullYear())}</span> - <span class="text-nowrap">${strings.allRightsAreReserved}</span> - <span class="text-nowrap">${strings.vatNoX}</span> - <span class="text-nowrap">${strings.createdByCompany}</span>`}}></Col>
			</Row>
			<Row>
				<Col className="px-0">
				{
					iconLinks.map((iconLink, index) => (
						<a key={index} className="mx-2 mx-sm-3" href={iconLink.url} target="_blank" rel="noreferrer"><img src={iconLink.path} alt={iconLink.alt} /></a>
					))
				}
				</Col>
			</Row>
			<Row>
				<Col>
				{
					textLinks.map((textLink, index) => (
						<a key={index} className="mx-3 user-select-none transparent-cursor" href={textLink.url} target="_blank" rel="noreferrer">{textLink.text}</a>
					))
				}
				</Col>
			</Row>
			<Row>
				<Col><img src="/images/separator_small.svg" alt="" /></Col>
			</Row>
			<Row>
				<Col className="user-select-none transparent-cursor" dangerouslySetInnerHTML={{__html: strings.formatString(strings.readOurReviews, `<a href="https://it.trustpilot.com/review/ht-apps.eu?utm_medium=trustbox&utm_source=MicroReviewCount" target="_blank" rel="noreferrer"><img id="trustpilot-icon" alt="Trustpilot" src="/images/trustpilot.svg" /></a>`)}}></Col>
			</Row>
		</Container>
	)
};

export default HomeFooter;
