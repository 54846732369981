import {createContext, useState} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import API from "./API.js";
import Account from "./pages/Account.js";
import Admins from "./components/Admins.js";
import Dashboard from "./components/Dashboard.js";
import ForgotPassword from "./components/ForgotPassword.js";
import Home from "./pages/Home.js";
import Landing from "./components/Landing.js";
import Login from "./components/Login.js";
import NotFound from "./components/NotFound.js";
import ResetPassword from "./components/ResetPassword.js";
import Settings from "./components/Settings.js";
import ShowUsers from "./components/ShowUsers.js";
import Signup from "./components/Signup.js";

const SessionContext = createContext();

function App() {
	const [loggedIn, setLoggedIn] = useState(API.isLoggedIn);
	const session = API.getSession();
	const role = (session && session.role) ? session.role : "";

	return (
		<SessionContext.Provider value={setLoggedIn}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={(loggedIn) ? <Account /> : <Home />}>
						<Route index element={(loggedIn) ? <Dashboard redirect={true} /> : <Landing />} />
						<Route path="login" element={(loggedIn) ? <Dashboard redirect={true} /> : <Login />} />
						<Route path="signup" element={(loggedIn) ? <Dashboard redirect={true} /> : <Signup />} />
						<Route path="forgot-password" element={(loggedIn) ? <Dashboard redirect={true} /> : <ForgotPassword />} />
						<Route path="reset-password" element={(loggedIn) ? <Dashboard redirect={true} /> : <Landing redirect={true} />} />
						<Route path="reset-password/:token" element={(loggedIn) ? <Dashboard redirect={true} /> : <ResetPassword />} />
						<Route path="dashboard" element={(loggedIn) ? <Dashboard /> : <Landing redirect={true} />} />
						<Route path="admins" element={(loggedIn) ? ((role === "superadmin") ? <Admins /> : <Dashboard redirect={true} />) : <Landing redirect={true} />} />
						<Route path="settings" element={(loggedIn) ? <Settings /> : <Landing redirect={true} />} />
						<Route path="lms" element={(loggedIn) ? <Dashboard redirect={true} /> : <Landing redirect={true} />} />
						<Route path="lms/:lmsID" element={(loggedIn) ? <ShowUsers /> : <Landing redirect={true} />} />
						<Route path="*" element={(loggedIn) ? <Dashboard redirect={true} /> : <NotFound />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</SessionContext.Provider>
	);
};

export default App;
export {SessionContext};
