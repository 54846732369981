import {useEffect, useState} from "react";

const Breakpoint = Object.freeze({
	xs: 0,
	sm: 1,
	md: 2,
	lg: 3,
	xl: 4,
	xxl: 5
});

const resolveBreakpoint = (width) => {
	if (width < 576) {
		return Breakpoint.xs;
	} else if (width >= 576 && width < 768) {
		return Breakpoint.sm;
	} else if (width >= 768 && width < 992) {
		return Breakpoint.md;
	} else if (width >= 992 && width < 1200) {
		return Breakpoint.lg;
	} else if (width >= 1200 && width < 1440 ) {
		return Breakpoint.xl;
	} else if (width >= 1440) {
		return Breakpoint.xxl;
	}
};

const useBreakpoint = () => {
	const [breakpoint, setBreakpoint] = useState(() => resolveBreakpoint(window.innerWidth));
	const calcInnerWidth = () => setBreakpoint(resolveBreakpoint(window.innerWidth));

	useEffect(() => {
		window.addEventListener("resize", calcInnerWidth);
		return () => window.removeEventListener("resize", calcInnerWidth);
	}, [breakpoint]);

	return breakpoint;
};

export {Breakpoint, useBreakpoint};
