import axios from "axios";

class API {
	static server = process.env.REACT_APP_BASE_URL;

	/*static async getReCAPTCHAToken(recaptchaRef) {
		if (recaptchaRef?.current) {
			try {
				return await recaptchaRef.current.executeAsync();
			} catch (error) {
				return false;
			}
		} else {
			return false;
		}
	}*/

	/*static addRecaptchaTokenToData(recaptchaToken, data) {
		return {...data, "g-recaptcha-response": recaptchaToken};
	}*/

	static async signUp(/*recaptchaToken, */name, email, password) {
		const result = {};

		try {
			const data = /*API.addRecaptchaTokenToData(recaptchaToken,*/ {
				"username": name,
				"email": email,
				"password": password,
			};//);
			const response = await axios.post(`${API.server}/admin`, data);

			result.statusCode = response.status;
		} catch (error) {
			result.statusCode = (error.response) ? error.response.status : 500;
		} finally {
			return result;
		}
	}

	static async login(/*recaptchaToken, */email, password) {
		const result = {};

		try {
			const data = {email, password};
			// const data = API.addRecaptchaTokenToData(recaptchaToken, {email, password});
			const response = await axios.post(`${API.server}/admin/getToken`, data);

			if (response.data) {
				const {token, exp, refresh_token, idAdmin, role} = response.data;
				localStorage.setItem("session", JSON.stringify({token, exp, refresh_token, idAdmin, role}));
				result.statusCode = response.status;
			}
		} catch (error) {
			result.statusCode = (error.response) ? error.response.status : 500;
		} finally {
			return result;
		}
	}

	static async logout() { // async is necessary
		localStorage.removeItem("session");
	}

	static getSession() {
		return localStorage.getItem("session") ? JSON.parse(localStorage.getItem("session")) : null;
	}

	static isLoggedIn() {
		const session = API.getSession();
		if (session) {
			const now = new Date().getTime();
			if (((session.exp * 1000) - now) >= 0) {
				return true;
			}
		}

		return false;
	}

	static isTokenAboutToExpire() {
		const session = API.getSession();
		if (session) {
			const now = new Date().getTime();
			if (((session.exp * 1000) - now) < 600000) { // less than 10 min
				return true;
			}
		}

		return false;
	}

	static async refreshToken() {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const response = await axios.post(`${API.server}/admin/refreshToken`, {
					refresh_token: session.refresh_token
				});
	
				if (response.data) {
					const {token, exp, refresh_token, idAdmin, role} = response.data;
					localStorage.setItem("session", JSON.stringify({token, exp, refresh_token, idAdmin, role}));
				}

				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async getAdmin(id) {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const response = await axios.get(`${API.server}/admin/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				result.data = (response.data instanceof Object) ? response.data : {};

				if (response.data instanceof Object) {
					result.data = response.data;

					if (response.data.image !== undefined && typeof response.data.image === "string") {
						result.data.image = `${API.server}/${response.data.image}`;
					}
				} else {
					result.data = {};
				}

				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async getLMSes() {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const response = await axios.get(`${API.server}/admin/${session.idAdmin}/lms`,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				result.data = Array.isArray(response.data) ? response.data : [];
				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async getLMS(lmsID) {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const response = await axios.get(`${API.server}/admin/${session.idAdmin}/lms/${lmsID}`,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				result.data = (response.data instanceof Object) ? response.data : {};
				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async newLMS(name, type, adminID, url, userName, password) {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const data = {
					"name": name,
					"statementsType": type,
					"idAdmin": adminID,
					"baseURL": url
				}
				if (userName) data.authUsername = userName;
				if (password) data.authPassword = password;

				const response = await axios.post(`${API.server}/admin/${session.idAdmin}/lms`, data,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async editLMS(id, name, type, adminID, url, userName, password) {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const data = {
					"name": name,
					"statementsType": type,
					"idAdmin": adminID,
					"baseURL": url
				};
				if (userName !== undefined) data.authUsername = userName;
				if (password !== undefined) data.authPassword = password;

				const response = await axios.put(`${API.server}/admin/${session.idAdmin}/lms/${id}`, data,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async deleteLMS(id) {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const response = await axios.delete(`${API.server}/admin/${session.idAdmin}/lms/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async getLMSUsers(lmsID) {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const response = await axios.get(`${API.server}/admin/${session.idAdmin}/lms/${lmsID}/users`,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				result.data = Array.isArray(response.data) ? response.data : [];
				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async deleteLMSUser(lmsID, id, userID, threeDeeAppId) {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const response = await axios.delete(`${API.server}/admin/${session.idAdmin}/lms/${lmsID}/users/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						},
						params: {
							idUsr: userID,
							idApp3D: threeDeeAppId
						}
					}
				);

				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async getAdmins() {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const response = await axios.get(`${API.server}/admin`,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				result.data = Array.isArray(response.data) ? response.data : [];
				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async editAdmin(id, name, email, password, image) {
		const result = {};
		const session = API.getSession();

		if (session) {
			const formData = new FormData();
			formData.append("username", name);
			formData.append("email", email);

			if (password) {
				formData.append("password", password);
			}

			let contentType = "application/json";
			if (image === "") {
				formData.append("removeImage", true);
			} else if (image instanceof Object) {
				formData.append("image", image);
				contentType = "multipart/form-data";
			}

			try {
				const response = await axios.put(`${API.server}/admin/${id}`, formData,
					{
						headers: {
							"Content-Type": contentType,
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				if (response.data instanceof Object && response.data.image !== undefined && typeof response.data.image === "string") {
					result.data = response.data;
					result.data.image = `${API.server}/${response.data.image}`;
				} else {
					result.data = {};
				}
				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async changeAdminState(id, state) {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const response = await axios.patch(`${API.server}/admin/${id}`,
					{state},
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async deleteAdmin(id) {
		const result = {};
		const session = API.getSession();

		if (session) {
			try {
				const response = await axios.delete(`${API.server}/admin/${id}`,
					{
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${session.token}`
						}
					}
				);

				result.statusCode = response.status;
			} catch (error) {
				result.statusCode = (error.response) ? error.response.status : 500;
			}
		} else {
			result.statusCode = 401;
		}

		return result;
	}

	static async sendContact(/*recaptchaToken, */firstName, surname, email, message, phone) {
		const result = {};
		const data = {
			"first_name": firstName,
			"surname": surname,
			"email": email,
			"message": message
		};
		if (phone) data.phone = phone;
		// data = API.addRecaptchaTokenToData(recaptchaToken, data);

		try {
			const response = await axios.post(`${API.server}/sendContact`, data);
			result.statusCode = response.status;
		} catch (error) {
			result.statusCode = (error.response) ? error.response.status : 500;
		} finally {
			return result;
		}
	}

	static async forgotPassword(/*recaptchaToken, */email) {
		const result = {};
		const data = /*API.addRecaptchaTokenToData(recaptchaToken, */{email};//);

		try {
			const response = await axios.post(`${API.server}/admin/forgotPassword`, data);
			result.statusCode = response.status;
		} catch (error) {
			result.statusCode = (error.response) ? error.response.status : 500;
		} finally {
			return result;
		}
	}

	static async resetPassword(id, email, newPassword, token) {
		const result = {};
		const data = /*API.addRecaptchaTokenToData(recaptchaToken, */{id, email, newPassword, token};//);

		try {
			const response = await axios.post(`${API.server}/admin/resetPassword`, data);
			result.statusCode = response.status;
		} catch (error) {
			result.statusCode = (error.response) ? error.response.status : 500;
		} finally {
			return result;
		}
	}
}

export default API;
