import {useContext, useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Container, Form, Pagination, Row, Spinner, Table} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {AccountContext} from "../pages/Account.js";
import {SessionContext} from "../App.js";
import API from "../API.js";
import ConfirmationBox from "./ConfirmationBox.js";
import EditLMS from "./EditLMS.js";
import strings from "../strings.js";
import "./ShowUsers.css";

const tableColumnNames = [
	strings.userID,
	strings.threeDeeAppId,
	strings.actions
];

function ShowUsers() {
	const [isLoading, setIsLoading] = useState(true);
	const [showEditLMSModal, setShowEditLMSModal] = useState(false);
	const [showDeleteLMSModal, setShowDeleteLMSModal] = useState(false);
	const [deleteUserModalState, setDeleteUserModalState] = useState(false);
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("");
	const navigate = useNavigate();
	const [setAccountContext] = useContext(AccountContext);
	const { lmsID } = useParams();
	const [name, setName] = useState("");
	const [url, setURL] = useState("");
	const [type, setType] = useState("");
	const [adminID, setAdminID] = useState(null);
	const [adminLabel, setAdminLabel] = useState("");
	const [users, setUsers] = useState([]);
	const [usersFiltered, setUsersFiltered] = useState([]);
	const itemsPerPage = 5;
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const searchTermRef = useRef();
	const [totalPages, setTotalPages] = useState(Math.ceil(users.length / itemsPerPage));
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const usersOnCurrentPage = usersFiltered.slice(startIndex, endIndex);
	const session = API.getSession();
	const role = (session && session.role) ? session.role : "";
	const setLoggedIn = useContext(SessionContext);

	const back = () => navigate(-1);

	const logout = () => API.logout().then(() => setLoggedIn(false));

	const closeAlert = () => {
		setAlert("");
		setAlertType("");

		if (searchTermRef.current) {
			searchTermRef.current.focus();
		}
	};

	const search = (e) => {
		setSearchTerm(e.target.value);
		setCurrentPage(1);
	};

	const removeLMS = () => {
		API.deleteLMS(lmsID)
			.then((result) => {
				if (result) {
					switch (result.statusCode) {
						case 200:
							back();
							break;
						case 401:
							logout();
							break;
						case 403:
							setAlertType("danger");
							setAlert(strings.unauthorisedRequest);
							break;
						case 400:
						case 404:
						case 422:
							setAlertType("warning");
							setAlert(strings.requestFailed);
							break;
						case 500:
							setAlertType("danger");
							setAlert(strings.serverError);
							break;
						default:
					}
				}
			});
	};

	const removeLMSUser = (id, userID, threeDeeAppId) => {
		API.deleteLMSUser(lmsID, id, userID, threeDeeAppId)
			.then((result) => {
				if (result) {
					switch (result.statusCode) {
						case 200:
							setUsers(users.filter((user) => (user.id !== id)));
							break;
						case 400:
						case 404:
							setAlertType("warning");
							setAlert(strings.requestFailed);
							break;
						case 401:
							logout();
							break;
						case 403:
							setAlertType("danger");
							setAlert(strings.unauthorisedRequest);
							break;
						case 422:
							setAlertType("warning");
							setAlert(strings.requestFailed);
							break;
						case 500:
							setAlertType("danger");
							setAlert(strings.serverError);
							break;
						default:
					}
				}
			});
	};

	useEffect(() => {
		API.getLMS(lmsID)
			.then((result) => {
				if (result) {
					switch (result.statusCode) {
						case 200:
							setName(result.data.name ?? "");
							setURL(result.data.baseURL ?? "");
							setType(result.data.statementsType ?? "");
							setAdminID(result.data.idAdmin ?? "");
							break;
						case 401:
							logout();
							break;
						case 403:
							setAlertType("danger");
							setAlert(strings.unauthorisedRequest);
							break;
						case 404:
							setAlertType("warning");
							setAlert(strings.requestFailed);
							break;
						case 500:
							setAlertType("danger");
							setAlert(strings.serverError);
							break;
						default:
					}
				}
			});

		API.getLMSUsers(lmsID)
			.then((result) => {
				if (result) {
					switch (result.statusCode) {
						case 200:
							setUsers(result.data);
							break;
						case 400:
							setAlertType("warning");
							setAlert(strings.requestFailed);
							break;
						case 401:
							logout();
							break;
						case 403:
							setAlertType("danger");
							setAlert(strings.unauthorisedRequest);
							break;
						default:
					}
				}
			});
	}, [lmsID]);

	useEffect(() => {
		if (adminID) {
			if (role === "superadmin") {
				API.getAdmin(adminID)
					.then((result) => {
						switch (result.statusCode) {
							case 200:
								setAdminLabel((result.data) ? `${result.data.username} (${result.data.email})` : "");
								setIsLoading(false);
								break;
							case 401:
								logout();
								break;
							case 404:
								setAlertType("warning");
								setAlert(strings.requestFailed);
								break;
							case 403:
								setAlertType("danger");
								setAlert(strings.unauthorisedRequest);
								break;
							case 500:
								setAlertType("danger");
								setAlert(strings.serverError);
								break;
							default:
						}
					});
			} else {
				setIsLoading(false);
			}
		}
	}, [adminID]);

	useEffect(() => {
		const filteredElements = users.filter((user) => 
			Object.values(Object.fromEntries(Object.entries(user).filter(([key]) => (key !== "id")))) // Excludes the "id" property from the search.
				.some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
		);
		setUsersFiltered(filteredElements);
		setTotalPages(Math.ceil(filteredElements.length / itemsPerPage));
	}, [users, searchTerm]);

	useEffect(() => {
		setAccountContext({currentPage: name, breadcrumbs: [
			{
				label: strings.uloi,
				url: "/"
			},
			{
				label: strings.lms,
				url: "/dashboard",
			},
			{
				label: name
			}
		]});
	}, [name, setAccountContext]);

	useEffect(() => {
		if (!isLoading && searchTermRef.current) {
			searchTermRef.current.focus();
		}
	}, [isLoading]);

	return (
		(isLoading) ?
			<Spinner animation="border" className="spinner" />
		:
			<>
				<Button className="standard-dark-button ms-2 ms-sm-0" onClick={back}>
					<img alt="<" src="/images/back.svg" />
					<span className="align-middle ms-3">{strings.back}</span>
				</Button>

				<Container id="details-container" className="mt-4">
					<Row className="pt-3 pb-1">
						<Col className="user-select-all transparent-cursor">{name}</Col>
					</Row>
					{
						(alert) &&
							<Row className="mt-3">
								<Col>
									<Alert variant={alertType} className="mb-0 user-select-none transparent-cursor" dismissible={true} onClose={closeAlert}>{alert}</Alert>
								</Col>
							</Row>
					}
					<Row className="mt-3">
						<Col className="col-12 col-md-8 truncated-text">
							<span className="user-select-none transparent-cursor fw-bold">{strings.url}: </span>
							<span className="break-line break-all user-select-all transparent-cursor">{url}</span>
							<br />
							<span className="user-select-none transparent-cursor fw-bold">{strings.type}: </span>
							<span className="user-select-all transparent-cursor">{type}</span>
							<br />
							<span className="user-select-none transparent-cursor fw-bold">{strings.lmsID}: </span>
							<span className="user-select-all transparent-cursor">{lmsID}</span>
							{
								(role === "superadmin") &&
									<>
										<br />
										<span className="user-select-none transparent-cursor fw-bold">{strings.administrator}: </span>
										<span className="user-select-all transparent-cursor">{adminLabel}</span>
									</>
							}
						</Col>
						<Col className="col-12 col-md-4 mt-2 mt-md-0 text-end">
							<Row>
								<Col>
									<Button className="edit-button" onClick={() => setShowEditLMSModal(true)}>
										<img alt="" src="/images/edit.svg" />
										<span className="align-middle fw-bold ms-2 d-none d-xl-inline">{strings.editLMS}</span>
									</Button>
									<Button className="delete-button ms-2 ms-xl-3" onClick={() => setShowDeleteLMSModal(true)}>
										<img alt="" src="/images/delete.svg" />
										<span className="align-middle fw-bold ms-2 d-none d-xl-inline">{strings.deleteLMS}</span>
									</Button>
								</Col>
							</Row>
							<Row className="mt-3 mt-md-5">
								<Col>
									<Form.Control id="search-box" className="float-end" type="text" placeholder={strings.search} ref={searchTermRef} value={searchTerm} onChange={search} />
								</Col>
							</Row>
						</Col>
					</Row>

					<Row className="mt-3">
						<Col>
							<Table responsive striped>
								<thead>
									<tr>
									{
										tableColumnNames.map((columnName, index) => (
											<th key={index} className={`w-33 user-select-none transparent-cursor${(index === 0) ? " ps-3" : ""}`}>{columnName}</th>
										))	
									}
									</tr>
								</thead>
								<tbody>
								{
									(usersOnCurrentPage.length === 0) ?
										<tr>
											<td className="py-3 text-center user-select-none transparent-cursor" colSpan="4">{strings.noResults}</td>
										</tr>
									:
										usersOnCurrentPage.map((user, index) => (
											<tr key={index}>
												<td className="ps-3 user-select-all">{user.idUsr}</td>
												<td className="user-select-all">{user.idApp3D}</td>
												<td>
													<Button type="button" className="delete-button" onClick={() => setDeleteUserModalState(user)}>
														<img alt="" src="/images/delete.svg" />
														<span className="align-middle ms-2 d-none d-xl-inline">{strings.delete}</span>
													</Button>
												</td>
											</tr>
										))
								}
								</tbody>
							</Table>
							{
								(totalPages > 0) &&
									<Pagination className="d-flex justify-content-center">
										<Pagination.First className="user-select-none transparent-cursor" onClick={() => setCurrentPage(1)} />
										<Pagination.Prev className="user-select-none transparent-cursor" onClick={() => setCurrentPage((currentPage > 1) ? (currentPage - 1) : 1)} />
										{
											[...Array(totalPages)].map((_, index) => (
												<Pagination.Item className="user-select-none transparent-cursor" key={index + 1} active={(index + 1) === currentPage} onClick={() => setCurrentPage(index + 1)}>
													{index + 1}
												</Pagination.Item>
											))
										}
										<Pagination.Next className="user-select-none transparent-cursor" onClick={() => setCurrentPage((currentPage < totalPages) ? (currentPage + 1) : totalPages)} />
										<Pagination.Last className="user-select-none transparent-cursor" onClick={() => setCurrentPage(totalPages)} />
									</Pagination>
							}
						</Col>
					</Row>
				</Container>

				<EditLMS show={showEditLMSModal} setShow={setShowEditLMSModal} id={lmsID} name={name} type={type} adminID={adminID} url={url} showUsers={{setName, setType, setAdminID, setAdminLabel, setURL}} />
				<ConfirmationBox show={showDeleteLMSModal} setShow={setShowDeleteLMSModal} action={removeLMS} />
				<ConfirmationBox show={deleteUserModalState} setShow={setDeleteUserModalState} action={() => removeLMSUser(...Object.values(deleteUserModalState))} />
			</>
	)
}

export default ShowUsers;
