import {useContext, useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {SessionContext} from "../App.js";
import API from "../API.js";
import Select from "react-select";
import Utils from "../Utils.js";
import strings from "../strings.js";

function NewLMS({show, setShow, refreshPage}) {
	const session = API.getSession();
	const role = (session && session.role) ? session.role : "";
	const adminID = session?.idAdmin ?? 0;
	const initialState = {name: "", type: "XAPI", adminID: (role !== "superadmin") ? adminID : 0, url: "", username: "", password: ""};
	const setLoggedIn = useContext(SessionContext);
	const nameRef = useRef();
	const adminRef = useRef();
	const urlRef = useRef();
	const userNameRef = useRef();
	const passwordRef = useRef();
	const credentialsTooltipRef = useRef();
	const passwordTooltipRef = useRef();
	const [isLoading, setIsLoading] = useState(true);
	const [formData, setFormData] = useState(initialState);
	const [admins, setAdmins] = useState([]);
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("");
	const [errors, setErrors] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const [showCredentialsTooltip, setShowCredentialsTooltip] = useState(false);

	const logout = () => API.logout().then(() => setLoggedIn(false));

	const initialFocus = () => {
		if (nameRef.current) {
			nameRef.current.focus();
		}
	};

	const closeModal = () => {
		setAlert("");
		setAlertType("");
		setFormData(initialState);
		setErrors({});
		setShow(false);
	};

	const closeAlert = () => {
		setAlert("");
		setAlertType("");
		initialFocus();
	};

	const onChangeValue = (event) => {
		const {name, value} = event.target;
		setFormData((prevFormData) => ({...prevFormData, [name]: value}));
		setErrors({...errors, [name]: false})
	};

	const onSelectAdmin = (admin) => {
		setFormData((prevFormData) => ({...prevFormData, adminID: admin?.id ?? 0}));
		setErrors({...errors, admin: false});
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
		passwordRef.current.focus();
	};

	const areDataSaved = (JSON.stringify(formData) === JSON.stringify(initialState));

	const formValidation = (formData) => {
		let errorMessages = {};
		let firstErrorField = null;

		if (formData.name.length === 0) {
			errorMessages.name = strings.requiredField;
			firstErrorField = nameRef;
		}

		if ((role === "superadmin") && !formData.adminID) {
			errorMessages.admin = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = adminRef;
			}
		}

		if (formData.url.length === 0) {
			errorMessages.url = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = urlRef;
			}
		} else if (!Utils.isURLValid(formData.url)) {
			errorMessages.url = strings.invalidURLFormat;
			if (!firstErrorField) {
				firstErrorField = urlRef;
			}
		}

		if (formData.username.length > 0 && formData.password.length === 0) {
			errorMessages.password = strings.passwordIsRequiredWhenUserNameIsPresent;
			if (!firstErrorField) {
				firstErrorField = passwordRef;
			}
		}

		if (formData.password.length > 0) {
			if (formData.username.length === 0) {
				errorMessages.userName = strings.userNameIsRequiredWhenPasswordIsPresent;
				if (!firstErrorField) {
					firstErrorField = userNameRef;
				}
			} else if (!Utils.isPasswordStrong(formData.password)) {
				errorMessages.password = strings.passwordIsTooWeak;
				if (!firstErrorField) {
					firstErrorField = passwordRef;
				}
			}
		}

		return {errorMessages, firstErrorField};
	};

	const newLMS = (event) => {
		event.preventDefault();
		setAlert("");
		setAlertType("");

		const data = Utils.trimFields(formData);
		const {errorMessages, firstErrorField} = formValidation(data);

		if (Object.keys(errorMessages).length > 0) {
			(firstErrorField ? firstErrorField : nameRef).current.focus();
			setErrors(errorMessages);
		} else {
			API.newLMS(...Object.values(data))
				.then((result) => {
					if (result) {
						switch (result.statusCode) {
							case 201:
								refreshPage();
								closeModal();
								break;
							case 401:
								logout();
								break;
							case 403:
								setAlertType("danger");
								setAlert(strings.unauthorisedRequest);
								setFormData(data);
								break;
							case 409:
								setAlertType("warning");
								setAlert(strings.lmsConflictNameText);
								setFormData(data);
								break;
							case 400:
							case 422:
								setAlertType("warning");
								setAlert(strings.requestFailed);
								setFormData(data);
								break;
							case 500:
								setAlertType("danger");
								setAlert(strings.serverError);
								setFormData(data);
								break;
							default:
						}
					}
				});
		}
	};

	useEffect(() => {
		if (show) {
			if (role === "superadmin") {
				API.getAdmins()
					.then((result) => {
						if (result) {
							switch (result.statusCode) {
								case 200:
									setAdmins(result.data.sort((a, b) => ((a.username < b.username) ? -1 : ((a.username === b.username) ? 0 : 1)))
										.map((admin) => (
										{
											id: admin.id,
											label: `${admin.username} (${admin.email})`
										})
									));
									setIsLoading(false);
									break;
								case 401:
									logout();
									break;
								case 403:
									setAlertType("danger");
									setAlert(strings.unauthorisedRequest);
									break;
								case 500:
									setAlertType("danger");
									setAlert(strings.serverError);
									break;
								default:
							}
						}
					});
			} else {
				setIsLoading(false);
			}
		}
	}, [show]);

	useEffect(() => {
		if (!isLoading) {
			initialFocus();
		}
	}, [isLoading]);

	useEffect(() => {
		const onClickOutside = (event) => { // Hides the tooltip when the user clicks or taps outside of it.
			if (credentialsTooltipRef.current && !credentialsTooltipRef.current.contains(event.target)) {
				setShowCredentialsTooltip(false);
			}
		}
		document.addEventListener("mousedown", onClickOutside);

		return () => document.removeEventListener("mousedown", onClickOutside);
	}, [credentialsTooltipRef]);

	useEffect(() => {
		const onClickOutside = (event) => { // Hides the tooltip when the user clicks or taps outside of it.
			if (passwordTooltipRef.current && !passwordTooltipRef.current.contains(event.target)) {
				setErrors({});
			}
		}
		document.addEventListener("mousedown", onClickOutside);

		return () => document.removeEventListener("mousedown", onClickOutside);
	}, [passwordTooltipRef]);

	return (
		<Modal show={show} onHide={closeModal} onEntered={initialFocus}>
			<Modal.Header>
				<Modal.Title className="user-select-none transparent-cursor">{strings.newLMS}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-body-min-height">
			{
				(isLoading) ?
					<Spinner animation="border" className="spinner" />
				:
					<>
						{
							(alert) &&
								<Row className="mb-2">
									<Col>
										<Alert variant={alertType} className="mb-0 user-select-none transparent-cursor" dismissible={true} onClose={closeAlert}>{alert}</Alert>
									</Col>
								</Row>
						}
						<Form className="mt-0 w-100" onSubmit={newLMS}>
							<Row>
								<Col>
									<Form.Group>
										<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="new-lms-name">{strings.name}</Form.Label>
										<Form.Control type="text" id="new-lms-name" name="name" className={`${(errors.name) ? "error-border" : ""}`} placeholder={strings.name} ref={nameRef} value={formData.name ?? ""} onChange={onChangeValue} />
										{(errors.name) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="new-lms-name">{errors.name}</Form.Label>}
									</Form.Group>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col className="inline-grid col-12 col-sm-5">
									<span className="standard-label user-select-none transparent-cursor">{strings.type}</span>
									<Form.Group>
										<Form.Check inline type="radio" id="new-lms-xapi" name="type" className="me-2" value="XAPI" checked={formData.type === "XAPI"} onChange={onChangeValue} />
										<Form.Label className="standard-label align-middle user-select-none transparent-none" htmlFor="new-lms-xapi">{strings.xapi}</Form.Label>
										<Form.Check inline type="radio" id="new-lms-scorm" name="type" className="ms-3 me-2" value="SCORM" checked={formData.type === "SCORM"} onChange={onChangeValue} />
										<Form.Label className="standard-label align-middle user-select-none transparent-none" htmlFor="new-lms-scorm">{strings.scorm}</Form.Label>
									</Form.Group>
								</Col>
								{
									(role === "superadmin") &&
										<Col className="inline-grid col-12 col-sm-7 mt-3 mt-sm-0">
											<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="new-lms-admin">{strings.administrator}</Form.Label>
											<Select
												id="new-lms-admin"
												className={`${(errors.admin) ? "error-border" : ""}`}
												classNamePrefix="select" /* Important: check out "select__value-container" in index.css . */
												isLoading={false}
												isClearable={true}
												isRtl={false}
												isSearchable={true}
												options={admins}
												placeholder={strings.administrator}
												ref={adminRef}
												value={admins.find((admin) => (admin.id === formData.adminID))}
												getOptionValue={(admin) => (admin?.id)} /* Necessary to avoid a rendering bug since I am using "id" instead of "value". */
												onChange={onSelectAdmin}
											/>
											{(errors.admin) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="new-lms-admin">{errors.admin}</Form.Label>}
										</Col>
								}
							</Row>
							<Row className="mt-3">
								<Col>
									<Form.Group>
										<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="new-lms-url">{strings.url}</Form.Label>
										<Form.Control type="text" id="new-lms-url" name="url" className={`${(errors.url) ? "error-border" : ""}`} placeholder={strings.url} ref={urlRef} value={formData.url ?? ""} onChange={onChangeValue} />
										{(errors.url) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="new-lms-url">{errors.url}</Form.Label>}
									</Form.Group>
								</Col>
							</Row>
							<Row className="separator mt-3">
								<Col><img alt="" className="w-100" src="/images/line.svg" /></Col>
								<Col className="px-1"><img alt="" src="/images/logo_grey_wheel_only.png" /></Col>
								<Col><img alt="" className="w-100" src="/images/line.svg" /></Col>
							</Row>
							<Row className="mt-3">
								<Col className="my-auto position-relative">
									<div id="info-button" className="float-end user-select-none transparent-cursor" onClick={() => setShowCredentialsTooltip(true)}>i</div>
									{(showCredentialsTooltip) && <div id="credentials-tooltip" className="user-select-none transparent-cursor" dangerouslySetInnerHTML={{__html: strings.credentialsTooltipText}} ref={credentialsTooltipRef}></div>}
								</Col>
							</Row>
							<Row className="mt-2">
								<Col>
									<Form.Group>
										<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="new-lms-user-name">{strings.newUserName}</Form.Label>
										<Form.Control type="text" id="new-lms-user-name" name="username" className={`${(errors.userName) ? "error-border" : ""}`} placeholder={strings.newUserName} ref={userNameRef} value={formData.username ?? ""} onChange={onChangeValue} />
										{(errors.userName) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="new-lms-user-name">{errors.userName}</Form.Label>}
									</Form.Group>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<Form.Group>
										<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="new-lms-password">{strings.newPassword}</Form.Label>
										<div className="password-field">
											<Form.Control type={showPassword ? "text" : "password"} id="new-lms-password" name="password" className={`${(errors.password) ? "error-border" : ""}`} autoComplete="new-password" placeholder={strings.newPassword} ref={passwordRef} value={formData.password ?? ""} onChange={onChangeValue} />
											<img alt={showPassword ? strings.hide : strings.show} onClick={toggleShowPassword} src={showPassword ? "/images/eye_slashed.svg" : "/images/eye.svg"} />
											{
												(errors.password) &&
													<Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="new-lms-password">{errors.password}</Form.Label>
											}
											{
												(errors.password && errors.password === strings.passwordIsTooWeak) &&
													<div id="password-tooltip" className="user-select-none transparent-cursor" dangerouslySetInnerHTML={{__html: strings.passwordRequirementsText}} ref={passwordTooltipRef}></div>
											}
										</div>
									</Form.Group>
								</Col>
							</Row>
							<Row className="mt-4">
								<Col>
									<Button type="submit" className="standard-dark-button" disabled={areDataSaved}>
										<img alt="+" src="/images/plus.svg" />
										<span className="align-middle ms-2">{strings.add}</span>
									</Button>
									<Button type="button" className="standard-light-button ms-3" onClick={closeModal}>{strings.cancel}</Button>
								</Col>
							</Row>
						</Form>
					</>
			}
			</Modal.Body>
		</Modal>
	);
}

export default NewLMS;
