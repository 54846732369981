import {useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import API from "../API.js";
// import ReCAPTCHA from "react-google-recaptcha";
import Utils from "../Utils.js";
import strings from "../strings.js";
import "./ForgotPassword.css";

function ForgotPassword() {
	const emailRef = useRef();
	// const recaptchaRef = useRef();
	const [emailSent, setEmailSent] = useState(false);
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("");
	const [errors, setErrors] = useState({});

	const closeAlert = () => {
		setAlert("");
		setAlertType("");

		if (emailRef.current) {
			emailRef.current.focus();
		}
	};

	const trimFields = () => {
		emailRef.current.value = emailRef.current.value.trim();
	};

	const formValidation = () => {
		let errorMessages = {};
		let firstErrorField = null;

		if (emailRef.current.value.length === 0) {
			errorMessages.email = strings.requiredField;
			firstErrorField = emailRef;
		}

		if (emailRef.current.value.length > 0 && !Utils.isEmailValid(emailRef.current.value)) {
			errorMessages.email = strings.invalidEmailFormat;
			if (!firstErrorField) {
				firstErrorField = emailRef;
			}
		}

		return {errorMessages, firstErrorField};
	}

	const forgotPassword = (event) => {
		event.preventDefault();
		setAlert("");
		setAlertType("");
		trimFields();

		const {errorMessages, firstErrorField} = formValidation();
		if (Object.keys(errorMessages).length > 0) {
			(firstErrorField ? firstErrorField : emailRef).current.focus();
			setErrors(errorMessages);
		} else {
			// const recaptchaToken = API.getReCAPTCHAToken(recaptchaRef);

			API.forgotPassword(/*recaptchaToken, */emailRef.current.value)
				.then((result) => {
					if (result) {
						switch (result.statusCode) {
							case 201:
								setEmailSent(true);
								break;
							case 400:
							case 422:
								setAlertType("warning");
								setAlert(strings.requestFailed);
								break;
							case 404:
								setAlertType("warning");
								setAlert(strings.emailNotRegistered);
								break;
							case 500:
								setAlertType("danger");
								setAlert(strings.serverError);
								break;
							default:
						}
					}

					if (emailRef.current) {
						emailRef.current.focus();
					}
				});
		}
	};
	
	useEffect(() => {
		if (emailRef.current) {
			emailRef.current.focus();
		}
	}, []);

	return (
		<Container className="home-inner-container home-inner-container-size px-4 px-md-5 py-4 py-md-5">
			{
				(alert) &&
					<Row className="mb-3">
						<Col className="px-2 px-md-4 px-xl-5">
							<Alert variant={alertType} className="mb-0 user-select-none transparent-cursor" dismissible={true} onClose={closeAlert}>{alert}</Alert>
						</Col>
					</Row>
			}
			<Row className="user-select-none transparent-cursor">
				<Col className="px-2 px-md-4 px-xl-5">
					<span className="home-inner-container-title">{strings.forgotPassword}</span><br />
					{(!emailSent) && <span>{strings.enterYourEmailToResetYourPassword}</span>}
				</Col>
			</Row>
			{
				(emailSent) ?
					<Row className="mt-4">
						<Col id="email-successfully-sent" className="px-2 px-md-4 px-xl-5 user-select-none transparent-cursor">
							{strings.emailSuccessfullySent}
						</Col>
					</Row>
				:
					<Form className="mt-4 d-grid gap-3" onSubmit={forgotPassword}>
						<Row>
							<Col className="px-2 px-md-4 px-xl-5">
								<Form.Group>
									<Form.Control type="text" id="forgot-password-email" className={`${(errors.email) ? "error-border" : ""}`} placeholder={`${strings.email}*`} ref={emailRef} onChange={() => setErrors({...errors, email: false})} />
									{(errors.email) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="forgot-password-email">{errors.email}</Form.Label>}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="px-2 px-md-4 px-xl-5">
								<Button type="submit" className="w-100">{strings.next}</Button>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col id="required-fields-text" className="px-2 px-sm-4 px-xl-5 user-select-none transparent-cursor">
								{strings.theFieldsMarkedWithAnAsteriskAreRequired}
							</Col>
						</Row>
						{/* <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible" /> */}
					</Form>
			}
		</Container>
	);
};

export default ForgotPassword;
