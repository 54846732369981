import {useContext, useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import API from "../API.js";
import {SessionContext} from "../App.js";
import Utils from "../Utils.js";
import strings from "../strings.js";

function EditAdmin({show, setShow, id, name, email, adminTable}) {
	const setLoggedIn = useContext(SessionContext);
	const nameRef = useRef();
	const emailRef = useRef();
	const passwordRef = useRef();
	const confirmPasswordRef = useRef();
	const passwordTooltipRef = useRef();
	const [initialState, setInitialState] = useState({});
	const [formData, setFormData] = useState(initialState);
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("");
	const [errors, setErrors] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const logout = () => API.logout().then(() => setLoggedIn(false));

	const initialFocus = () => {
		if (nameRef.current) {
			nameRef.current.focus();
		}
	};

	const closeModal = () => {
		setAlert("");
		setAlertType("");
		setFormData(initialState);
		setErrors({});
		setShow(false);
	};

	const closeAlert = () => {
		setAlert("");
		setAlertType("");
		initialFocus();
	};

	const onChangeValue = (event) => {
		const {name, value} = event.target;
		setFormData((prevFormData) => ({...prevFormData, [name]: value}));
		setErrors({...errors, [name]: false})
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
		passwordRef.current.focus();
	};

	const toggleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
		confirmPasswordRef.current.focus();
	};

	const areDataSaved = (JSON.stringify(formData) === JSON.stringify(initialState));

	const formValidation = (formData) => {
		let errorMessages = {};
		let firstErrorField = null;

		if (formData.name.length === 0) {
			errorMessages.name = strings.requiredField;
			firstErrorField = nameRef;
		}

		if (formData.email.length === 0) {
			errorMessages.email = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = emailRef;
			}
		} else if (!Utils.isEmailValid(formData.email)) {
			errorMessages.email = strings.invalidEmailFormat;
			if (!firstErrorField) {
				firstErrorField = emailRef;
			}
		}

		if (formData.password.length > 0 && !Utils.isPasswordStrong(formData.password)) {
			errorMessages.password = strings.passwordIsTooWeak;
			if (!firstErrorField) {
				firstErrorField = passwordRef;
			}
		}

		if (confirmPasswordRef.current.value !== formData.password) {
			errorMessages.confirmPassword = strings.passwordsDontMatch;
			if (!firstErrorField) {
				firstErrorField = confirmPasswordRef;
			}
		}

		return {errorMessages, firstErrorField};
	};

	const editAdmin = (event) => {
		event.preventDefault();
		setAlert("");
		setAlertType("");

		const data = Utils.trimFields(formData);
		const {errorMessages, firstErrorField} = formValidation(data);

		if (Object.keys(errorMessages).length > 0) {
			(firstErrorField ? firstErrorField : nameRef).current.focus();
			setErrors(errorMessages);
		} else {
			API.editAdmin(...Object.values(formData))
				.then((result) => {
					if (result) {
						switch (result.statusCode) {
							case 200:
								if (adminTable) {
									adminTable.setAdmins(adminTable.admins.map((admin) => {
										if (admin.id === id) {
											return {...admin, username: data.name, email: data.email};
										} else {
											return admin;
										}
									}))
								}

								closeModal();
								break;
							case 401:
								logout();
								break;
							case 403:
								setAlertType("danger");
								setAlert(strings.unauthorisedRequest);
								setFormData(data);
								break;
							case 400:
							case 404:
							case 422:
								setAlertType("warning");
								setAlert(strings.requestFailed);
								setFormData(data);
								break;
							case 409:
								setAlertType("warning");
								setAlert(strings.signupAlreadyRegisteredText);
								setFormData(data);
								break;
							case 500:
								setAlertType("danger");
								setAlert(strings.serverError);
								setFormData(data);
								break;
							default:
						}
					}
				});
		}
	};

	useEffect(() => {
		if (show) {
			setInitialState({id, name, email, password: ""});
			setFormData({id, name, email, password: ""});
		}
	}, [show]);

	useEffect(() => {
		const onClickOutside = (event) => { // Hides the tooltip when the user clicks or taps outside of it.
			if (passwordTooltipRef.current && !passwordTooltipRef.current.contains(event.target)) {
				setErrors({});
			}
		}
		document.addEventListener("mousedown", onClickOutside);

		return () => document.removeEventListener("mousedown", onClickOutside);
	}, [passwordTooltipRef]);

	return (
		<Modal show={show} onHide={closeModal} onEntered={initialFocus}>
			<Modal.Header>
				<Modal.Title className="user-select-none transparent-cursor">{strings.formatString(strings.editAdminX, name)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					(alert) &&
						<Row className="mb-2">
							<Col>
								<Alert variant={alertType} className="mb-0 user-select-none transparent-cursor" dismissible={true} onClose={closeAlert}>{alert}</Alert>
							</Col>
						</Row>
				}
				<Form className="mt-0 w-100" onSubmit={editAdmin}>
					<Row>
						<Col>
							<Form.Group>
								<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="edit-admin-name">{strings.name}</Form.Label>
								<Form.Control type="text" id="edit-admin-name" name="name" className={`${(errors.name) ? "error-border" : ""}`} placeholder={strings.name} ref={nameRef} value={formData.name ?? ""} onChange={onChangeValue} />
								{(errors.name) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="edit-admin-name">{errors.name}</Form.Label>}
							</Form.Group>
						</Col>
					</Row>
					<Row className="mt-3">
						<Col>
							<Form.Group>
								<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="edit-admin-email">{strings.email}</Form.Label>
								<Form.Control type="text" id="edit-admin-email" name="email" className={`${(errors.email) ? "error-border" : ""}`} placeholder={strings.email} ref={emailRef} value={formData.email ?? ""} onChange={onChangeValue} />
								{(errors.email) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="edit-admin-email">{errors.email}</Form.Label>}
							</Form.Group>
						</Col>
					</Row>
					<Row className="mt-3">
						<Col className="col-12 col-md-6">
							<Form.Group>
								<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="edit-admin-password">{strings.password}</Form.Label>
								<div className="password-field">
									<Form.Control type={showPassword ? "text" : "password"} id="edit-admin-password" name="password" className={`${(errors.password) ? "error-border" : ""}`} autoComplete="new-password" placeholder={strings.password} ref={passwordRef} value={formData.password ?? ""} onChange={onChangeValue} />
									<img alt={showPassword ? strings.hide : strings.show} onClick={toggleShowPassword} src={showPassword ? "/images/eye_slashed.svg" : "/images/eye.svg"} />
									{
										(errors.password) &&
											<>
												<Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="edit-admin-password">{errors.password}</Form.Label>
												<div id="password-tooltip" className="user-select-none transparent-cursor" dangerouslySetInnerHTML={{__html: strings.passwordRequirementsText}} ref={passwordTooltipRef}></div>
											</>
									}
								</div>
							</Form.Group>
						</Col>
						<Col className="mt-3 mt-md-0 col-12 col-md-6">
							<Form.Group>
								<Form.Label className="standard-label user-select-none transparent-cursor" htmlFor="edit-admin-confirm-password">{strings.confirmPassword}</Form.Label>
								<div className="password-field">
									<Form.Control type={showConfirmPassword ? "text" : "password"} id="edit-admin-confirm-password" className={`${(errors.confirmPassword) ? "error-border" : ""}`} autoComplete="new-password" placeholder={strings.confirmPassword} ref={confirmPasswordRef} onChange={() => setErrors({...errors, confirmPassword: false})} />
									<img alt={showConfirmPassword ? strings.hide : strings.show} onClick={toggleShowConfirmPassword} src={showConfirmPassword ? "/images/eye_slashed.svg" : "/images/eye.svg"} />
									{(errors.confirmPassword) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="edit-admin-confirm-password">{errors.confirmPassword}</Form.Label>}
								</div>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col>
							<Button type="submit" className="standard-dark-button" disabled={areDataSaved}>
								<img alt="" src="/images/floppy_disk.svg" />
								<span className="align-middle ms-2">{strings.save}</span>
							</Button>
							<Button type="button" className="standard-light-button ms-3" onClick={closeModal}>{strings.cancel}</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default EditAdmin;
