import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {Button, Col, Container, Nav, NavDropdown, Row} from "react-bootstrap";
import {List as IconList, X as IconX} from "react-bootstrap-icons";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Breakpoint, useBreakpoint} from "../hooks/useBreakpoint.js";
import API from "../API.js";
import NewLMS from "../components/NewLMS.js";
import {SessionContext} from "../App.js";
import links from "../links.js";
import strings from "../strings.js";
import "./Account.css";

const AccountContext = createContext();

function Account() {
	const breakpoint = useBreakpoint();
	const location = useLocation();
	const sidebarMenuRef = useRef();
	const [accountImage, setAccountImage] = useState(null);
	const [accountName, setAccountName] = useState("");
	const [activeMenuItem, setActiveMenuItem] = useState(0);
	const [menuOpen, setMenuOpen] = useState(false);
	const [menuExpanded, setMenuExpanded] = useState(true);
	// const [notifications, setNotifications] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const navigate = useNavigate();
	const session = API.getSession();
	const setLoggedIn = useContext(SessionContext);
	const role = (session && session.role) ? session.role : "";

	const logout = () => API.logout().then(() => setLoggedIn(false));

	const refreshPage = () => setRefresh(!refresh);

	const newLMSButton =
		<Button className="d-flex my-auto px-3 px-lg-4 py-2" onClick={() => setShowModal(true)}>
			<img alt="+" className="my-auto" src="/images/plus.svg" />
			<span className={`my-auto ms-3 align-middle ${(breakpoint >= Breakpoint.lg) ? "d-inline" : "d-none"}`}>
				{strings.newLMS}
			</span>
		</Button>;

	const newLMSModal = <NewLMS show={showModal} setShow={setShowModal} refreshPage={refreshPage} />;

	let menuItems = [
		{
			label: strings.dashboard,
			iconPath: "/images/dashboard.svg",
			activeIconPath: "/images/dashboard_active.svg",
			url: "dashboard",
			dropdown: false,
			superadminOnly: false,
			button: newLMSButton,
			modal: newLMSModal
		},
		{
			label: strings.administrators,
			iconPath: "/images/users.svg",
			activeIconPath: "/images/users_active.svg",
			url: "admins",
			dropdown: false,
			superadminOnly: true
		},
		{
			label: strings.settings,
			iconPath: "/images/settings.svg",
			activeIconPath: "/images/settings_active.svg",
			url: "settings",
			dropdown: true,
			superadminOnly: false
		},
		{
			label: strings.logOut,
			iconPath: "/images/logout.svg",
			activeIconPath: "/images/logout.svg",
			action: logout,
			dropdown: true,
			superadminOnly: false
		}
	];
	if (role && role !== "superadmin") {
		menuItems = menuItems.filter((menuItem) => !menuItem.superadminOnly);
	}

	const [accountContext, setAccountContext] = useState({currentPage: "", breadcrumbs: []});

	const formatBreadcrumbs = () => {
		let breadcrumbs = [];

		if (!Array.isArray(accountContext.breadcrumbs) || accountContext.breadcrumbs.length === 0) {
			breadcrumbs = [<span id="current-page">{strings.uloi}</span>];
		} else if (accountContext.breadcrumbs.length === 1) {
			breadcrumbs = [<span id="current-page">{accountContext.breadcrumbs[0].label}</span>];
		} else {
			breadcrumbs = [<Link to={accountContext.breadcrumbs[0].url}>{accountContext.breadcrumbs[0].label}</Link>];

			for (let i = 1; i < accountContext.breadcrumbs.length - 1; i++) {
				breadcrumbs = [...breadcrumbs, " / ", <Link to={accountContext.breadcrumbs[i].url}>{accountContext.breadcrumbs[i].label}</Link>];
			}

			breadcrumbs = [...breadcrumbs, " / ", <span id="current-page">{accountContext.breadcrumbs[accountContext.breadcrumbs.length - 1].label}</span>];
		}

		return breadcrumbs;
	};

	const textLinks = [
		{
			text: strings.privacyPolicy,
			url: links.privacyPolicy
		},
		{
			text: strings.termsOfUse,
			url: links.termsOfUse
		}
	];

	const menuItemClick = (event) => {
		event.preventDefault();
		
		const itemIndex = event.currentTarget.getAttribute("data-item-index");
		if (menuItems[itemIndex].url && location.pathname) {
			if (location.pathname.slice(1) === menuItems[itemIndex].url) {
				navigate(menuItems[itemIndex].url, {replace: true});
				refreshPage();
			} else {
				navigate(menuItems[itemIndex].url);
			}
		}

		if (menuItems[itemIndex].action) {
			const action = menuItems[itemIndex].action;
			action();
		}

		setMenuOpen(false);
	};

	const menuItemPressed = (event) => {
		event.preventDefault();

		if (event.key === "Enter" || event.code === "Enter" ||
			event.key === " " || event.code === "Space") {
			menuItemClick(event);
		}
	};

	useEffect(() => {
		if (session) {
			API.getAdmin(session.idAdmin, session.token)
				.then((result) => {
					switch (result.statusCode) {
						case 200:
							setAccountImage(result.data.image);
							setAccountName(result.data.username);
							break;
						case 401:
						case 403:
						case 404:
							logout();
							break;
						case 500:
						default:
					}
				});
		}
	}, []);

	useEffect(() => {
		setMenuOpen(false);
	}, [breakpoint]);

	useEffect(() => {
		const onClickOutside = (event) => { // Hides the sidebar when the user clicks or taps outside of it.
			if (sidebarMenuRef.current && !sidebarMenuRef.current.contains(event.target)) {
				setMenuOpen(false);
			}
		}
		document.addEventListener("mousedown", onClickOutside);

		return () => document.removeEventListener("mousedown", onClickOutside);
	}, [sidebarMenuRef]);

	useEffect(() => {
		if (API.isLoggedIn()) {
			const menuItemToSelect = menuItems.findIndex((menuItem) => (menuItem.url && menuItem.url === location.pathname.slice(1)));
			setActiveMenuItem((menuItemToSelect === -1) ? 0 : menuItemToSelect);

			if (API.isTokenAboutToExpire()) {
				API.refreshToken();
			}
		} else {
			logout();
		}
	}, [location.pathname]);

	return (
		<>
			<Container fluid id="account-container" className="p-0">
				<Row>
					{
						(menuOpen || (breakpoint >= Breakpoint.lg)) &&
							<Col id="sidebar-menu" className={`vh-100 pt-4 ${(menuOpen || menuExpanded) ? "sidebar-menu-large-screen" : "sidebar-menu-small-screen"}`} ref={sidebarMenuRef}>
								<Row className={`align-items-center ps-3 pe-2 ${(menuOpen || menuExpanded) ? "ps-lg-4 pe-lg-3" : ""}`}>
									<Col>
										<Link to="/" tabIndex="-1">
											<img alt="" src={(menuOpen || menuExpanded) ? "/images/logo_menu.svg" : "/images/logo_menu_wheel_only.svg"} />
										</Link>
									</Col>
									<Col>
										{
											(menuOpen && breakpoint < Breakpoint.lg) ?
												<Button type="button" id="x-icon" className="float-end transparent-button p-0" onClick={() => setMenuOpen(!menuOpen)}>
													<IconX size={40} />
												</Button>
											:
												<Button type="button" className="float-end transparent-button p-0" onClick={() => setMenuExpanded(!menuExpanded)}>
													<img alt="" src={(menuExpanded) ? "/images/collapse_menu.svg" : "/images/expand_menu.svg"} />
												</Button>
										}
										
									</Col>
								</Row>
								<Row className={`mt-5 px-3 ${(menuOpen || menuExpanded) ? "px-lg-4" : ""}`}>
									<Col className="d-flex">
										<ul>
										{
											menuItems.map((menuItem, index) => (
												<li key={index} className={`user-select-none transparent-cursor menu-item ${(index === Number(activeMenuItem)) ? "menu-item-active" : ""}`} data-item-index={index} tabIndex={index + 1} onClick={menuItemClick} onKeyUp={menuItemPressed}>
													<img alt="" src={menuItem.iconPath} />
													<span className={`align-middle ms-3 ${(menuOpen || menuExpanded) ? "d-lg-inline" : "d-none"}`}>{menuItem.label}</span>
												</li>
											))
										}
										</ul>
									</Col>
								</Row>
							</Col>
					}
					<Col className={`d-flex flex-column min-vh-100 ${(breakpoint >= Breakpoint.lg) ? ((menuExpanded) ? "right-panel-expanded" : "right-panel-collapsed") : "right-panel-compact"} ${(menuOpen && breakpoint < Breakpoint.lg) ? "opacity-25" : ""}`}>
						<Row id="welcome-bar" className="px-2 pe-md-3 px-lg-4 py-0">
							{
								(breakpoint >= Breakpoint.lg) ?
									<Col className="user-select-none transparent-cursor my-auto" dangerouslySetInnerHTML={{__html: strings.formatString(strings.welcomeUser, `<span id="user-name" class="fw-bold">${accountName}</span>`)}}></Col>
								:
									<Col className="col-2 my-auto px-2">
										<Button type="button" id="hamburger-icon" className="user-select-none transparent-cursor" onClick={() => setMenuOpen(!menuOpen)}>
											<IconList size={40} />
										</Button>
									</Col>
							}
							<Col className="d-flex flex-row justify-content-end me-2 me-md-0 me-md-0 user-select-none transparent-cursor">
								<Nav>
									<NavDropdown id="dropdown-user-name" className="my-auto" title={
										<div className="d-flex flex-row">
											<div id="image-circle" className="me-2 my-auto d-flex align-items-baseline justify-content-center">
												<img alt="" src={accountImage ?? "/images/avatar.svg"} crossOrigin="anonymous" />
											</div>
											<span className="my-auto">{accountName}</span>
										</div>
									}>
									{
										menuItems.map((menuItem, index) => // I can't use filter() here so as not to lose original indexes
											(menuItem.dropdown) &&
												<NavDropdown.Item key={index} className="px-3" data-item-index={index} onClick={menuItemClick}>
													<img alt="" src={menuItem.iconPath} />
													<span className="ms-2 align-middle">{menuItem.label}</span>
												</NavDropdown.Item>
										)
									}
									</NavDropdown>
									{/* <NavDropdown id="dropdown-notifications" title={<img alt="" className="ms-2 my-auto" src="/images/bell_button.svg" />}>
										<NavDropdown.Header>{strings.notifications}</NavDropdown.Header>
										{
											notifications.map((notification, index) => (
												<NavDropdown.Item key={index} className="py-2">
													<div>{notification.text}</div>
													<img alt="&times;" className="float-end" src="/images/x.svg" />
												</NavDropdown.Item>
											))
										}
									</NavDropdown> */}
								</Nav>
							</Col>
						</Row>
						<Row id="account-page" className="px-2 px-md-3 px-lg-4">
							<Col>
								<Row id="navigation-bar" className="px-2 px-md-0 py-2 user-select-none transparent-cursor">
									<Col>
										<Row>
											<Col className="lh-sm">{accountContext.currentPage}</Col>
										</Row>
										<Row className="mt-1">
											<Col>
												{
													formatBreadcrumbs()
														.map((breadcrumb, index) =>
															<React.Fragment key={index}>{breadcrumb}</React.Fragment>
														)
												}
											</Col>
										</Row>
									</Col>
									<Col className="col-auto align-items-end">{menuItems[activeMenuItem].button}</Col>
								</Row>
								<Row className="px-sm-2 px-md-0 py-3">
									<Col>
										<AccountContext.Provider value={[setAccountContext, setAccountImage, setAccountName]}>
											{
												/*
												Important:
												Using the "key" attribute with the refresh state serves as a mechanism to
												force a re-render of the Outlet component when the "refresh" state changes.
												*/
											}
											<Outlet key={refresh} />
										</AccountContext.Provider>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row id="page-footer" className="px-2 px-md-3 px-lg-4 user-select-none transparent-cursor">
							<Col className="pt-2 pb-3 col-12 col-xl-4 page-footer-border">
							{
								textLinks.map((textLink, index) => (
									<a key={index} className="me-4" href={textLink.url} target="_blank" rel="noreferrer">{textLink.text}</a>
								))
							}
							</Col>
							<Col className={`pt-2 pb-3 col-12 col-xl-8 ${(breakpoint >= Breakpoint.xl) ? "page-footer-border" : ""}`}>
								<span className={(breakpoint >= Breakpoint.xl) ? "float-end" : ""} dangerouslySetInnerHTML={{__html: `<span class="text-nowrap">${strings.formatString(strings.companyYears, (new Date()).getFullYear())}</span> - <span class="text-nowrap">${strings.allRightsAreReserved}</span> - <span class="text-nowrap">${strings.vatNoX}</span> - <span class="text-nowrap">${strings.createdByCompany}</span>`}}></span>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>

			{menuItems[activeMenuItem].modal}
		</>
	);
}

export default Account;
export {AccountContext};
